<template>
  <div>
    <b-row class="mb-1 mx-1">
      <b-col>
        <div class="mt-2 d-flex justify-content-end align-items-center">
          <div class="mr-1">
            <b-form-datepicker
              v-model="dateFrom"
              placeholder="Date from"
              class="rounded min-width-inputs"
              locale="en"
              :max="today"
            />
          </div>

          <div class="mr-1">
            <b-form-datepicker
              v-model="dateTo"
              placeholder="Date to"
              class="rounded min-width-inputs"
              locale="en"
              :min="dateFrom"
              :max="dateToMax"
              :disabled="!dateFrom"
            />
          </div>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle text-left"
            @click="dashboardRefresh"
          >
            <b-spinner small v-if="isLoading" />
            <feather-icon v-else icon="SearchIcon" />
          </b-button>
        </div>
      </b-col>

    </b-row>

    <b-row class="dashboard-cards">

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'outbox'}" class="card-text-color">
          <statistic-card-horizontal
            icon="MessageCircleIcon"
            :statistic="sent.toLocaleString()"
            :statistic-title="cardTitles.sent"
            color="primary"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'outbox'}" class="card-text-color">
          <statistic-card-horizontal
            icon="MessageSquareIcon"
            :statistic="delivered.toLocaleString()"
            :statistic-title="cardTitles.delivered"
            color="success"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'outbox'}" class="card-text-color">
          <statistic-card-horizontal
            icon="XCircleIcon"
            :statistic="failed.toLocaleString()"
            :statistic-title="cardTitles.failed"
            color="danger"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'scheduled-messages'}" class="card-text-color">
          <statistic-card-horizontal
            icon="CalendarIcon"
            :statistic="scheduled.toLocaleString()"
            :statistic-title="cardTitles.scheduled"
            color="warning"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'client-all-contacts'}" class="card-text-color">
          <statistic-card-horizontal
            icon="PhoneCallIcon"
            :statistic="contacts.toLocaleString()"
            :statistic-title="cardTitles.contacts"
            color="info"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'client-groups'}" class="card-text-color">
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="groups.toLocaleString()"
            :statistic-title="cardTitles.groups"
            color="primary"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'sender-id'}" class="card-text-color">
          <statistic-card-horizontal
            icon="SendIcon"
            :statistic="senderActive.toLocaleString()"
            :statistic-title="cardTitles.senderActive"
            color="success"
          />
        </b-link>
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <b-link :to="{name: 'sender-id'}" class="card-text-color">
          <statistic-card-horizontal
            icon="LoaderIcon"
            :statistic="senderPending.toLocaleString()"
            :statistic-title="cardTitles.senderPending"
            color="info"
          />
        </b-link>
      </b-col>
      
      <b-col>
        <b-card>
          <chartjs-component-bar-chart
            :height="400"
            :data="barChart.data"
            :options="barChart.options"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormDatepicker,
  BLink,
} from "bootstrap-vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import ChartjsComponentBarChart from "./components/ChartjsComponentBarChart.vue";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BLink,
    StatisticCardHorizontal,
    ChartjsComponentBarChart,
  },
  computed: {
    barChart() {
      return {
        data: {
          labels: ["Confirmation Pending SMS(s)", "Delivered SMS(s)", "Failed SMS(s)"],
          datasets: [
            {
              data: [this.sent, this.delivered, this.failed],
              backgroundColor: ["#0052ff", "#28c76f", "#ea5455"],
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          title: {
            display: true,
            text: "SMS(s) STATUS REPORT",
            align: "start",
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  min: 0,
                  precision: 0,
                },
              },
            ],
          },
        },
      };
    },
  },
  watch: {
    dateFrom(newVal) {
      let newValDateFrom = new Date(newVal);
      let newValDateFromAddMonths = newValDateFrom.setMonth(
        newValDateFrom.getMonth() + 3
      );
      newValDateFromAddMonths = new Date(newValDateFromAddMonths)

      if (newValDateFromAddMonths > this.today) {
        this.dateToMax = this.today;
      } else {
        this.dateToMax = newValDateFromAddMonths;
      }
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    return {
      cardTitles: {
        sent: "Confirmation Pending SMS(s)",
        delivered: "Delivered SMS(s)",
        failed: "Failed SMS(s)",
        scheduled: "Scheduled Campaigns",
        contacts: "Contacts",
        groups: "Contact Groups",
        senderActive: "Active Sender ID",
        senderPending: "Pending Sender ID",
      },
      sent: 0,
      delivered: 0,
      failed: 0,
      scheduled: 0,
      contacts: 0,
      groups: 0,
      senderActive: 0,
      senderPending: 0,
      dateFrom: null,
      dateTo: null,
      today: new Date(today),
      dateToMax: new Date(today),
      isLoading: false,
    };
  },
  methods:{
    dashboardRefresh(){
      this.isLoading = true;
      this.$http.get("/dashboard/" + "?date_from=" + this.dateFrom + "&date_to=" + this.dateTo).then((response) => {
        let dashboard = response.data.data;
        this.sent = dashboard.sent;
        this.delivered = dashboard.delivered;
        this.failed = dashboard.failed;
        this.scheduled = dashboard.scheduled;
        this.contacts = dashboard.contacts;
        this.groups = dashboard.groups;
        this.senderActive = dashboard.sender_active;
        this.senderPending = dashboard.sender_pending;
        this.isLoading = false;
      });
    },
    formatDateToSql(val){
      var d = new Date(val),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  created() {
    this.$http.get("/dashboard/").then((response) => {
      let dashboard = response.data.data;
      this.sent = dashboard.sent;
      this.delivered = dashboard.delivered;
      this.failed = dashboard.failed;
      this.scheduled = dashboard.scheduled;
      this.contacts = dashboard.contacts;
      this.groups = dashboard.groups;
      this.senderActive = dashboard.sender_active;
      this.senderPending = dashboard.sender_pending;
    });
    
    const dateFromVal = new Date(this.today);
    dateFromVal.setMonth(dateFromVal.getMonth() - 1);

    this.dateFrom = this.formatDateToSql(dateFromVal);
    this.dateTo = this.formatDateToSql(this.today);
  },
};
</script>

<style>
.dashboard-cards .apexcharts-tooltip {
  display: none !important;
}
.min-width-inputs {
  min-width: 15rem;
}
.card-text-color{
  color: #6e6b7b !important;
}
</style>
